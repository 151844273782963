import { useEffect, useState } from 'react';

import { getAssetAspectDetails } from 'utils/media';

import { AssetSummary, Dimensions, VideoMediaAsset } from 'types/media';

export type LoadedVideoDetails = AssetSummary & {
  src: string;
  type: 'video';
};

/**
 * @returns video details once loaded, otherwise null
 */
export default function useLoadedVideo(
  media: VideoMediaAsset | null
): LoadedVideoDetails | null {
  const [revealed, setRevealed] = useState(false);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!media) return;

    const videoEl = document.createElement('video');

    function handleVideoLoaded(ev: Event) {
      const video = ev.target as HTMLVideoElement;

      if (!video) return;

      setRevealed(true);
      setDimensions({
        width: video.videoWidth,
        height: video.videoHeight,
      });
    }

    videoEl.addEventListener('loadedmetadata', handleVideoLoaded);

    videoEl.src = media?.src;

    return () => {
      videoEl.removeEventListener('loadedmetadata', handleVideoLoaded);
    };
  }, [media?.src]);

  if (!revealed) {
    return null;
  }

  if (!media) {
    return null;
  }

  const assetAspectDetails = getAssetAspectDetails(dimensions);

  return {
    ...dimensions,
    ...assetAspectDetails,
    src: media.src,
    type: 'video',
  };
}
