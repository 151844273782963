import { GridIcon } from '@f8n/icons';
import { onGrid } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';
import { ReactNode } from 'react';

import { H1Heading } from 'components/base/Heading';
import Text from 'components/base/Text';

import InlineLink from './InternalLink';
import { LearnMoreLink } from './LearnMoreLink';
import Box from './base/Box';
import Button from './base/Button';

const EmptyRoot = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  margin: '0 auto',
  textAlign: 'center',

  variants: {
    align: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
    },
    variant: {
      tab: {
        minHeight: onGrid(85),
        maxWidth: '45ch',
        paddingY: '$6',
      },
      modal: {
        minHeight: onGrid(40),
      },
    },
  },

  defaultVariants: {
    variant: 'tab',
  },
});

const EmptyHeading = styled(H1Heading, {
  marginBottom: '$4',
});
EmptyHeading.defaultProps = {
  size: 4,
  weight: 'medium',
};

const EmptySubheading = styled('p', Text, {
  textWrap: 'balance',
});
EmptySubheading.defaultProps = {
  color: 'dim',
  size: 2,
  lineHeight: 3,
  weight: 'regular',
};

const EmptyActions = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '$2',
});

const EmptyIconWrapper = styled('div', {
  color: '$black5',
  marginBottom: '$5',
});

const ICON_SIZE = 4;

/**
 * Icons to be used in Empty states (feel free to add more as needed)
 */
const emptyIconMap = {
  grid: <GridIcon size={ICON_SIZE} />,
};

type EmptyIconType = keyof typeof emptyIconMap;

type RootProps = ComponentProps<typeof EmptyRoot>;

type EmptyStateProps = {
  actions?: React.ReactNode;
  align?: RootProps['align'];
  heading: string;
  icon?: EmptyIconType;
  subheading: string | React.ReactNode;
  variant?: RootProps['variant'];
  image?: ReactNode;
};

function EmptyState(props: EmptyStateProps) {
  const { actions, align, heading, icon, subheading, variant, image } = props;

  const iconEl = icon ? emptyIconMap[icon] : null;

  return (
    <EmptyRoot align={align} variant={variant}>
      {image && <Box css={{ marginBottom: '$5' }}>{image}</Box>}
      {iconEl && <EmptyIconWrapper>{iconEl}</EmptyIconWrapper>}
      <EmptyHeading>{heading}</EmptyHeading>
      <EmptySubheading css={{ marginBottom: actions ? '$5' : 0 }}>
        {subheading}
      </EmptySubheading>
      {actions && <EmptyActions>{actions}</EmptyActions>}
    </EmptyRoot>
  );
}

type EmptyClearFiltersProps = {
  message: string;
  onClear(): void;
};

function EmptyStateWhenFiltersApplied(props: EmptyClearFiltersProps) {
  const { message, onClear } = props;

  return (
    <Empty
      heading="No results"
      subheading={message}
      actions={
        <>
          <Button variant="primary" onClick={() => onClear()}>
            Clear filters
          </Button>
        </>
      }
    />
  );
}

const Empty = Object.assign(EmptyState, {
  // Compositions
  WithFilters: EmptyStateWhenFiltersApplied,
  // Subcomponents
  Root: EmptyRoot,
  Heading: EmptyHeading,
  Subheading: EmptySubheading,
  InlineLink: InlineLink,
  LearnMoreLink: LearnMoreLink,
});

export default Empty;
