import { path } from 'ramda';

import {
  HELP_CENTER_HOME,
  HelpCenterArticleName,
  helpCenterPaths,
} from './router';

export const getHelpCenterPath = (
  articleName: HelpCenterArticleName
): string => {
  return path(articleName.split('.'), helpCenterPaths) || HELP_CENTER_HOME;
};
