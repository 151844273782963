import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';

import { getAssetAspectDetails } from 'utils/media';

import { AssetSummary, Dimensions, ImageFormatConfig } from 'types/media';

export type LoadedImageDetails = AssetSummary & ImageFormatConfig;

/**
 * @returns image details once loaded, otherwise null
 */
export default function useLoadedImage(
  config: ImageFormatConfig | null
): LoadedImageDetails | null {
  const [revealed, setRevealed] = useState(false);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const src = match(config)
    .with(null, () => null)
    .with({ type: 'vector' }, ({ raster }) => raster)
    .otherwise(({ src }) => src);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.onload = () => {
        setRevealed(true);

        setDimensions({
          width: img.width,
          height: img.height,
        });
      };

      img.src = src;
    }
  }, [src]);

  if (!config) {
    return null;
  }

  if (!revealed) {
    return null;
  }

  const assetAspectDetails = getAssetAspectDetails(dimensions);

  return {
    ...dimensions,
    ...assetAspectDetails,
    ...config,
  };
}
