import { differenceInSeconds, fromUnixTime, isBefore } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useHarmonicIntervalFn } from 'react-use';

import { DURATION_UNITS } from 'copy/duration';

import { getCountdownParts } from 'utils/countdown';
import {
  formatCountdownBreakdown,
  getDurationCountdownBreakdown,
  padDays,
  padTime,
} from 'utils/duration';
import { isEmptyOrNil } from 'utils/helpers';

import { Countdown } from 'types/countdown';
import { CountdownBreakdown, CountdownMaxUnits } from 'types/duration';

type CountdownState = {
  breakdown: CountdownBreakdown;
  secondsRemaining: number;
};

export default function useCountdown(
  unixTimestamp: number,
  options: Partial<{ maxUnits: CountdownMaxUnits }> = {}
): Countdown {
  const { maxUnits = 2 } = options;

  const getCoundownState = useCallback((): CountdownState | null => {
    const startDate = new Date();
    const endDate = fromUnixTime(unixTimestamp);

    if (isBefore(endDate, startDate)) return null;

    const breakdown = getDurationCountdownBreakdown({
      endDate,
      startDate,
    });

    const secondsRemaining = differenceInSeconds(endDate, startDate);

    return {
      breakdown,
      secondsRemaining,
    };
  }, [unixTimestamp]);

  const [countdown, setCountdown] = useState<CountdownState | null>(
    getCoundownState
  );

  const calculateCountdown = useCallback(() => {
    setCountdown(getCoundownState());
  }, [getCoundownState]);

  // reset countdown when timestamp changes
  useEffect(() => {
    calculateCountdown();
  }, [unixTimestamp]);

  // re-calculate countdown every 1s
  useHarmonicIntervalFn(calculateCountdown, unixTimestamp ? 1000 : null);

  if (!countdown) {
    return {
      countdownParts: [],
      hasEnded: true,
      message: null,
      secondsRemaining: 0,
    };
  }

  const { breakdown } = countdown;
  const { days, hours, minutes, seconds } = countdown.breakdown;

  const legacyCountdownParts = [
    {
      shortLabel: DURATION_UNITS.days,
      value: days,
      formattedValue: padDays(days),
    },
    {
      shortLabel: DURATION_UNITS.hours,
      value: hours,
      formattedValue: padTime(hours),
    },
    {
      shortLabel: DURATION_UNITS.minutes,
      value: minutes,
      formattedValue: padTime(minutes),
    },
    {
      shortLabel: DURATION_UNITS.seconds,
      value: seconds,
      formattedValue: padTime(seconds),
    },
  ] as const;

  const formattedParts = getCountdownParts(legacyCountdownParts);

  return {
    /** @deprecated */
    countdownParts: formattedParts,
    /** @deprecated */
    hasEnded: isEmptyOrNil(formattedParts),

    message: formatCountdownBreakdown(breakdown, { maxUnits }),
    secondsRemaining: countdown.secondsRemaining,
  };
}
