import { InfiniteData } from '@tanstack/react-query';

import { HASURA_DEFAULT_ITEMS_PER_PAGE } from 'lib/constants';

import { ApiPaginatableData } from 'types/Api';
import {
  AlgoliaInfinitePaginator,
  ApiInfinitePaginator,
  HasuraInfiniteLimitOffsetPaginator,
} from 'types/react-query';
import { UnsafeAny } from 'types/utils';

const getNextPageNumber = <Item extends Record<string, unknown>>(
  lastPage: ApiPaginatableData<Item>
): number | null => {
  const { page, totalPages } = lastPage;

  // subtract 1 because page starts at 0
  return page !== totalPages - 1 ? page + 1 : null;
};

export const apiPaginator: ApiInfinitePaginator = {
  getNextPageParam: (lastPage) => {
    const nextPage = getNextPageNumber(lastPage);

    if (!nextPage) return null;

    // Note: this is returned as an object, because that's what the generated infinite query hooks expect.
    return { page: nextPage };
  },
  initialPageParam: { page: 0 },
};

function hasuraLimitOffsetPaginator(options: {
  limit: number;
}): HasuraInfiniteLimitOffsetPaginator {
  const limit = options.limit;

  return {
    initialPageParam: {
      offset: 0,
      limit,
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.items.length < limit) {
        return undefined;
      }

      return {
        limit,
        offset: allPages.length * limit,
      };
    },
    getUniqueItemsByKey: (items, keyName) => {
      const unique = new Map(items.map((item) => [item[keyName], item]));
      return [...unique.values()];
    },
    /**
     * Flattens the items from an infinite query into a single array.
     * Assumes that the data returns an array of pages, each with an array of items.
     *
     * @example
     * const query = useInfiniteQuery(...)
     * const items = flattenItems(query.data)
     */
    flattenItems: (data) => {
      if (!data) {
        return [];
      }
      return data.pages.flatMap((page) => page.items);
    },
  };
}

export const hasuraPaginator = hasuraLimitOffsetPaginator({
  limit: HASURA_DEFAULT_ITEMS_PER_PAGE,
});

/**
 * Handles nested paginated data.
 * Assumes that the data returns an array of pages, each with a nested pagination object.
 *
 * @example
 * const query = useInfiniteQuery(...)
 * const nftData = extractNestedPaginatedData(query.data, 'nfts')
 * const nfts = nftData.items
 * const totalNftsCount = nftData.totalItemsCount
 */
export const extractNestedPaginatedData = <
  Data extends Record<string, UnsafeAny>,
  Key extends keyof Data,
>(
  data: InfiniteData<Data> | undefined,
  key: Key
): { items: Data[Key]['items']; totalItemsCount: number } => {
  return {
    /** Flattens all items into a single array */
    items: data?.pages.flatMap((page) => page[key].items) ?? [],
    /** Extracts the totalItemsCount from the first page. This assumes that `totalItemsCount` is equal for each page */
    totalItemsCount: data?.pages[0]?.[key].totalItems ?? 0,
  };
};

/**
 * Generated query hooks expose a getKey function that returns an array query key.
 *
 * In some scenarios, we want to extract the first item from that array, so that we can imperatively refetch multiple queries.
 *
 * @see https://tanstack.com/query/v4/docs/react/guides/filters#query-filters
 * @see https://tkdodo.eu/blog/effective-react-query-keys#structure
 *
 * @example
 * * // Example of a generated getKey function
 * useSomeData.getKey = (variables: SomeGeneratedVariables) => ['SomeQueryName', variables];
 *
 * * // Extract the root query key from getKey
 * const rootQueryKey = extractGeneratedQueryKeyRoot(useSomeData.getKey);
 *
 * * // Now use this key to re-fetch all SomeQueryName queries
 * queryClient.refetchQueries({ queryKey: rootQueryKey }})
 */
export const extractGeneratedQueryKeyRoot = (
  getKey: (queryVariables: UnsafeAny) => unknown[]
): string | null => {
  // Note that generated getKey functions require a `variables` argument.
  // In this context we pass an empty object, because we don't care about the variables.
  // We only care about the first value in the array, which we're assuming never includes the variables.
  const variables = {} as unknown;
  const queryKey = getKey(variables);
  const value = queryKey[0];

  // we're assuming that codegen always returns a string as the first item in the array
  return typeof value === 'string' ? value : null;
};

export const algoliaPaginator: AlgoliaInfinitePaginator = {
  getNextPageParam(lastPage) {
    const lastPageNumber = lastPage.page;
    const totalPageCount = lastPage.nbPages;
    const nextPageIndex = lastPageNumber + 1;

    return lastPageNumber < totalPageCount ? nextPageIndex : null;
  },
  initialPageParam: 0,
};
