import { match } from 'ts-pattern';

import { ImageFormatConfig, PreviewMediaAsset } from 'types/media';

import useLoadedImage, { LoadedImageDetails } from './use-loaded-image';
import useLoadedVideo, { LoadedVideoDetails } from './use-loaded-video';

type UseLoadedPreviewMediaOptions = {
  enabled: boolean;
};

/**
 * @returns asset details once loaded, otherwise null
 */
export default function useLoadedPreviewMedia(
  media: PreviewMediaAsset | null,
  options: UseLoadedPreviewMediaOptions = { enabled: true }
): LoadedImageDetails | LoadedVideoDetails | null {
  const { enabled } = options;

  const getImageConfig = () => {
    if (!enabled || !media) return null;
    return match<PreviewMediaAsset, ImageFormatConfig>(media)
      .with({ type: 'video' }, ({ poster }) => {
        return {
          type: 'raster',
          src: poster,
        };
      })
      .with({ type: 'vector' }, ({ src, raster }) => {
        return {
          type: 'vector',
          src,
          raster,
        };
      })
      .otherwise(({ src }) => {
        return {
          type: 'raster',
          src,
        };
      });
  };

  const getVideoSrc = () => {
    if (!enabled || !media) return null;
    return media?.type === 'video' ? media : null;
  };

  const loadedImage = useLoadedImage(getImageConfig());
  const loadedVideo = useLoadedVideo(getVideoSrc());

  if (!media) return null;

  // When there is no poster (or it's an empty string), calculate the sizing from the video instead.
  if (media.type === 'video' && !media.poster) {
    return loadedVideo;
  }

  return loadedImage;
}
