import { match } from 'ts-pattern';

import { ApiMediaFragment } from 'gql/api/api-fragments.generated';

import {
  AspectRatioName,
  AssetAspectDetails,
  AssetSummary,
  Dimensions,
  PreviewMediaAsset,
  MediaAsset,
  LaunchMediaAsset,
  OptimizeMediaAssetOptions,
  OptimizeImageOptions,
} from 'types/media';
import { Ratio } from 'types/number';

import { mapImgixGifToMp4, optimizeAsset, optimizeMetaImage } from './imgix';
import { isLandscapeMedia, isSquareMedia } from './media-dimension';
import { clampRatio } from './numbers';

export function getAspectRatioName(media: Dimensions): AspectRatioName {
  if (isLandscapeMedia(media)) {
    return 'landscape';
  }

  if (isSquareMedia(media)) {
    return 'square';
  }

  return 'portrait';
}

export function getAspectRatioCssString(media: Dimensions): string {
  return `${media.width} / ${media.height}`;
}

export function getAspectRatioDecimal(media: Dimensions): number {
  return media.width / media.height;
}

export function getAssetAspectDetails(media: Dimensions): AssetAspectDetails {
  return {
    aspectRatio: getAspectRatioCssString(media),
    aspectRatioDecimal: getAspectRatioDecimal(media),
  };
}

export function mapDimensionsToAssetSummary(
  dimensions: Dimensions
): AssetSummary {
  const { width, height } = dimensions;

  return {
    width,
    height,
    aspectRatio: getAspectRatioCssString({ width, height }),
    aspectRatioDecimal: getAspectRatioDecimal({ width, height }),
  };
}

type GetImageFillRatio = {
  asset: AssetSummary;
  container: Dimensions;
};

/**
 * Asset coverage = what percentage of the container does the asset fill?
 *
 * @param asset image or video poster. The Dimensions are assumed to be the natural dimensions of the asset.
 * @param container the container that the asset is being rendered inside.
 *
 * @returns a number between 0 and 1, with 1 being 100% coverage
 */
export function calculateAssetCoverage(options: GetImageFillRatio): number {
  const { asset, container } = options;

  // Avoid divide by zero problems, assume 100% coverage
  if (container.width === 0 || container.height === 0) {
    return 1;
  }

  // If either dimension is zero, the asset has no coverage
  if (asset.width === 0 || asset.height === 0) {
    return 0;
  }

  // Asset is the same size as container
  if (asset.width === container.width && asset.height === container.height) {
    return 1;
  }

  // Asset is smaller than container on both dimensions
  if (asset.width < container.width && asset.height < container.height) {
    return getCoverage({
      item: asset,
      container,
    });
  }

  const assetAspect = asset.aspectRatioDecimal;

  // Asset is portrait
  if (assetAspect < 1) {
    const item: Dimensions = {
      // Assume asset fills 100% of container height
      height: container.height,
      // Downsize width to maintain aspect ratio
      width: container.height * assetAspect,
    };

    return getCoverage({
      item,
      container,
    });
  }

  // Asset is landscape
  if (assetAspect > 1) {
    const item: Dimensions = {
      // Downsize height to maintain aspect ratio
      height: container.width / assetAspect,
      // Assume asset fills 100% of container width
      width: container.width,
    };

    return getCoverage({
      item,
      container,
    });
  }

  // At this point we can assume that the asset is square, but we need to know
  // the aspect ratio of the container to determine coverage.
  const containerAspect = getAspectRatioDecimal(container);

  // Container is portrait
  if (containerAspect < 1) {
    const item: Dimensions = {
      // Downsize height to maintain aspect ratio
      height: container.width / assetAspect,
      // Assume asset fills 100% of container width
      width: container.width,
    };

    return getCoverage({
      item,
      container,
    });
  }

  // Container is landscape
  if (containerAspect > 1) {
    const item: Dimensions = {
      // Assume asset fills 100% of container height
      height: container.height,
      // Downsize width to maintain aspect ratio
      width: container.height * assetAspect,
    };

    return getCoverage({
      item,
      container,
    });
  }

  // Container + asset are both square. Assume full coverage.
  return 1;
}

type GetCoverageOptions = {
  item: Dimensions;
  container: Dimensions;
};

/**
 * Asset coverage = what percentage of the container does the asset fill?
 *
 * @param asset image or video poster. The Dimensions are assumed to be the natural dimensions of the asset.
 * @param container the container that the asset is being rendered inside.
 *
 * @returns a number between 0 and 1, with 1 being 100% coverage
 */
const getCoverage = (options: GetCoverageOptions): Ratio => {
  const { item, container } = options;

  const coverageX = item.width / container.width;
  const coverageY = item.height / container.height;
  const coverage = coverageX * coverageY;

  return clampRatio(coverage);
};

export function mapApiMediaToOgImage(
  media: ApiMediaFragment | null
): string | null {
  return match(media)
    .with({ __typename: 'ImageMedia' }, (imageMedia) => {
      return optimizeMetaImage(imageMedia.url);
    })
    .with({ __typename: 'ModelMedia' }, (modelMedia) => {
      return optimizeMetaImage(modelMedia.modelStaticUrl);
    })
    .with({ __typename: 'VideoMedia' }, (videoMedia) => {
      // Note: video assets do not support imgix optimization
      return videoMedia.staticUrl;
    })
    .otherwise(() => null);
}

export function mapApiMediaToLaunchMedia(
  media: ApiMediaFragment | null,
  options: OptimizeMediaAssetOptions = { imageOptions: {} }
): LaunchMediaAsset | null {
  if (!media) {
    return null;
  }

  if (media.__typename === 'ModelMedia') {
    return {
      type: 'image',
      src: optimizeAsset(media.modelStaticUrl, options.imageOptions),
    };
  }

  if (media.__typename === 'VideoMedia') {
    return {
      type: 'video',
      src: media.previewUrl ?? media.url,
      poster: media.staticUrl ?? '',
    };
  }

  if (media.imageMimeType === 'IMAGE_GIF') {
    const video = mapImgixGifToMp4(media.url);
    return {
      type: 'video',
      src: video.src,
      poster: video.poster,
    };
  }

  return {
    type: 'image',
    src: optimizeAsset(media.url, options.imageOptions),
  };
}

const SHOWCASE_MEDIA_OPTIONS: OptimizeImageOptions = {
  dpr: 2,
  q: 70,
  h: 640,
  w: 640,
};

/**
 * casts 3D assets for 3D model viewer
 * & higher quality MP4s for videos
 */
export function mapApiNftMediaToShowcaseMedia(
  media: ApiMediaFragment | null
): MediaAsset | null {
  if (!media) {
    return null;
  }

  if (media.__typename === 'ModelMedia') {
    return {
      type: 'model',
      src: media.url,
      poster: optimizeAsset(media.modelStaticUrl, SHOWCASE_MEDIA_OPTIONS),
    };
  }

  if (media.__typename === 'VideoMedia') {
    return {
      type: 'video',
      src: media.url,
      poster: media.staticUrl
        ? optimizeAsset(media.staticUrl, SHOWCASE_MEDIA_OPTIONS)
        : '',
    };
  }

  if (media.imageMimeType === 'IMAGE_GIF') {
    const video = mapImgixGifToMp4(media.url);
    return {
      type: 'video',
      src: video.src,
      poster: optimizeAsset(video.poster, SHOWCASE_MEDIA_OPTIONS),
    };
  }

  return {
    type: 'image',
    src: optimizeAsset(media.url, SHOWCASE_MEDIA_OPTIONS),
  };
}

/**
 * casts 3D assets to static images
 */
export function mapShowcaseMediaToPreviewMedia(
  media: MediaAsset | null
): PreviewMediaAsset | null {
  if (!media) {
    return null;
  }
  if (media.type === 'model') {
    return {
      type: 'image',
      src: media.poster,
    };
  }

  return media;
}

const MINT_MEDIA_OPTIONS = {
  q: 70,
  h: 1200,
  w: 1200,
} as const;

const MINT_MEDIA_GIF_OPTIONS = {
  q: 70,
  auto: undefined,
  cs: undefined,
} as const;

const MINT_MEDIA_FULLSCREEN_IMAGE_OPTIONS = {
  q: 90,
  h: 3000,
  w: 3000,
} as const;

export function mapApiMediaToMintMedia(
  media: ApiMediaFragment | null
): MediaAsset | null {
  if (!media) {
    return null;
  }

  if (media.__typename === 'ModelMedia') {
    return {
      type: 'model',
      src: media.url,
      poster: optimizeAsset(media.modelStaticUrl, MINT_MEDIA_OPTIONS),
    };
  }

  if (media.__typename === 'VideoMedia') {
    return {
      type: 'video',
      src: media.url,
      poster: media.staticUrl
        ? optimizeAsset(media.staticUrl, MINT_MEDIA_OPTIONS)
        : '',
      controls: 'all',
    };
  }

  if (media.imageMimeType === 'IMAGE_GIF') {
    const video = mapImgixGifToMp4(media.url);
    return {
      type: 'video',
      src: video.src,
      poster: optimizeAsset(video.poster, MINT_MEDIA_GIF_OPTIONS),
    };
  }

  if (media.imageMimeType === 'IMAGE_SVG') {
    return {
      type: 'vector',
      src: media.url,
      raster: optimizeAsset(media.url, {
        ...MINT_MEDIA_OPTIONS,
        fm: 'auto',
      }),
    };
  }

  return {
    type: 'image',
    src: optimizeAsset(media.url, MINT_MEDIA_OPTIONS),
  };
}

export function mapApiMediaToFullscreenMintMedia(
  media: ApiMediaFragment | null
): PreviewMediaAsset | null {
  if (!media || media.__typename !== 'ImageMedia') {
    return null;
  }

  if (media.imageMimeType === 'IMAGE_GIF') {
    const video = mapImgixGifToMp4(media.url);
    return {
      type: 'video',
      src: video.src,
      poster: optimizeAsset(video.poster, MINT_MEDIA_OPTIONS),
    };
  }

  if (media.imageMimeType === 'IMAGE_SVG') {
    return {
      type: 'vector',
      src: media.url,
      raster: optimizeAsset(media.url, {
        ...MINT_MEDIA_FULLSCREEN_IMAGE_OPTIONS,
        fm: 'auto',
      }),
    };
  }

  return {
    type: 'image',
    src: optimizeAsset(media.url, MINT_MEDIA_FULLSCREEN_IMAGE_OPTIONS),
  };
}

export function mapApiMediaToAssetMedium(
  media: ApiMediaFragment | null
): string | null {
  return match(media)
    .with({ __typename: 'ImageMedia' }, (image) => {
      const mimeDescriptor = match(image.imageMimeType)
        .with('IMAGE_GIF', () => 'GIF')
        .with('IMAGE_JPEG', () => 'JPEG')
        .with('IMAGE_PNG', () => 'PNG')
        .with('IMAGE_SVG', () => 'SVG')
        .with('IMAGE_TIFF', () => 'TIFF')
        .with('IMAGE_WEBP', () => 'WebP')
        .otherwise(() => null);
      return mimeDescriptor ? `Image (${mimeDescriptor})` : 'Image';
    })
    .with({ __typename: 'VideoMedia' }, (video) => {
      const mimeDescriptor = match(video.videoMimeType)
        .with('VIDEO_MP4', () => 'MP4')
        .with('VIDEO_QUICKTIME', () => 'QuickTime')
        .with('VIDEO_WEBM', () => 'WebM')
        .otherwise(() => null);
      return mimeDescriptor ? `Video (${mimeDescriptor})` : 'Video';
    })
    .with({ __typename: 'ModelMedia' }, () => {
      return 'Model';
    })
    .otherwise(() => null);
}
