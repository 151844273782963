import NextLink from 'next/link';

import { GLOBAL_COPY } from 'copy/global';

import { getHelpCenterPath } from 'utils/help-center';
import { HelpCenterArticleName } from 'utils/router';

import Link from './base/Link';

export function LearnMoreLink(props: { article: HelpCenterArticleName }) {
  return (
    <NextLink href={getHelpCenterPath(props.article)} passHref>
      <Link variant="primary" hasUnderline target="_blank">
        {GLOBAL_COPY.learnMoreCta}
      </Link>
    </NextLink>
  );
}
