import { UrlObject } from 'url';

import NextLink from 'next/link';

import Link from 'components/base/Link';

/**
 * An opinionated link, designed to be used inside `<Text>` components.
 * Enforces a primary color and an underline.
 */
export default function InlineLink(props: {
  children: string;
  href: string | UrlObject;
}) {
  return (
    <NextLink href={props.href} passHref>
      <Link variant="primary" hasUnderline>
        {props.children}
      </Link>
    </NextLink>
  );
}
