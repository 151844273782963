export const MOMENTS_CTA = {
  CREATE: 'Create an exhibition',
  EDIT: 'Edit exhibition',
  DELETE: 'Delete exhibition',
  VIEW: 'View exhibition',
} as const;

export const MOMENT_CREATORS_COPY = {
  heading: 'Creators in this exhibition',
  empty: {
    heading: 'No creators yet',
    subheading:
      'Once a listing has been made in the exhibition, the creator will appear in this list.',
  },
} as const;

export const CREATE_MOMENT_COPY = {
  cta: MOMENTS_CTA.CREATE,
  loading: 'Creating Exhibition',
  success: 'Exhibition created',
} as const;

export const EDIT_MOMENT_COPY = {
  cta: MOMENTS_CTA.EDIT,
  success: 'Exhibition updated',
} as const;

export const DELETE_MOMENT_COPY = {
  cta: MOMENTS_CTA.DELETE,
  loading: 'Deleting exhibition',
  success: 'Exhibition deleted',
} as const;

export const MANAGE_MOMENT_COPY = {
  name: {
    label: 'Exhibition name',
    placeholder: 'Name',
  },
  description: {
    label: 'Description',
    placeholder: 'About this exhibition',
  },
  startDate: {
    label: 'Start date',
    description: 'Use this date to promote your exhibition',
    descriptionLocked: 'The start date is now locked for editing',
  },
  poster: {
    label: 'Exhibition image',
  },
  curatorFee: {
    label: 'Curator fee',
    description:
      'Collected from artists upon selling their work in this exhibition.',
  },
  customCuratorFee: {
    label: 'Custom take rate',
    placeholder: 'Enter a custom percentage from 0 to 50',
  },
  artists: {
    label: 'Artists',
    description:
      'Invite artists to be part of this exhibition and list their works for sale.',
  },
} as const;

export const MOMENT_DASHBOARD_COPY = {
  momentsList: {
    empty: {
      heading: 'Create your opening exhibition',
    },
  },
};

export const MOMENT_INVENTORY_COPY = {
  subheading:
    'Sellers invited to this exhibition can now list works. Once listed, works will automatically appear in your exhibition.',
  noDrops: 'There are no Drops in your exhibition, yet.',
  noEditions: 'There are no Editions in your exhibition, yet.',
  noNfts: 'There are no NFTs in your exhibition, yet.',
  removeItemCta: 'Remove from exhibition',
  add: {
    cta: 'Add to exhibition',
    loading: 'Adding to exhibition…',
  },
} as const;
