import { minutesToSeconds } from 'date-fns';
import { anyPass, compose, dropWhile, lte, prop } from 'ramda';

import type { CountdownPart } from 'types/countdown';

import { isEmptyOrNil } from './helpers';

// when the 'value' prop is <= 0 return true
const isValueNotZero = (num: number) => lte(num, 0);

// drop values that equal 0 from start of array
export const getCountdownParts = dropWhile<CountdownPart>(
  compose<CountdownPart, number, boolean>(
    anyPass([isValueNotZero, isNaN, isEmptyOrNil]),
    prop('value')
  )
);

export const isFinal5Mins = (secondsRemaining: number) => {
  return secondsRemaining < minutesToSeconds(5);
};
